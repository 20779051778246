
<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="2"
                    label-cols-md="3"
                    :label="$t('warehouse_config.fiscal_year')"
                    label-for="year"
                    >
                    <v-select name="fiscal_year_id"
                            v-model="search.fiscal_year_id"
                            label="text"
                            :options= fiscalList
                            :reduce="obj => obj.value"
                            :placeholder="$t('globalTrans.select')"
                            />
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_config.rent_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                      <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(year)="data">
                      {{ $n(data.item.year.split('-')[0], { useGrouping: false })}}-{{$n(data.item.year.split('-')[1], { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(quantity)="data">
                      {{ $n( data.item.quantity) }}
                    </template>
                    <template v-slot:cell(renting_amount)="data">
                     {{ $n( data.item.renting_amount) }}
                    </template>
                    <template v-slot:cell(remarks)="data">
                      {{ data.item.remarks }}
                    </template>
                    <template v-slot:cell(status)="data">
                    <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                    <b-badge variant="danger" v-if="data.item.status == 1">{{$t('globalTrans.inactive')}}</b-badge>
                     <b-badge variant="success" v-else>{{$t('globalTrans.active')}}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Warehouse Rent Modify" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Delete" @click="remove(data.item)"><i class="ri-delete-bin-line"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-if="pagination.totalRows"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" ok-title="Close" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>

  </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseRentList, warehouseRentToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        fiscal_year_id: null
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalList: function () {
        return this.$store.state.warehouse.fiscalYearList.filter(doc => doc.status === 0)
    },
    // fiscalList: function () {
    //     const fiscalYrList = this.$store.state.warehouse.fiscalYearList.filter(doc => doc.status === 0)
    //     if (this.currentLocale === 'bn') {
    //       return this.getFiscalYear(fiscalYrList)
    //     }
    //     return this.getFiscalYear(fiscalYrList)
    // },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('warehouse_config.rent_entry') : this.$t('warehouse_config.rent_update')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_config.fiscal_year'), class: 'text-left' },
          { label: this.$t('warehouse_config.quantity'), class: 'text-left' },
          { label: this.$t('warehouse_config.rent_amount'), class: 'text-left' },
          { label: this.$t('warehouse_config.remarks'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'year' },
          { key: 'quantity' },
          { key: 'renting_amount' },
          { key: 'remarks_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'year' },
          { key: 'quantity' },
          { key: 'renting_amount' },
          { key: 'remarks' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  async created () {
    this.loadData()
  },
  methods: {
    // getFiscalYear (fiscalYrList) {
    //     return fiscalYrList.map(item => {
    //       const tmp = item.text_en.split('-')
    //       return Object.assign(item, { text: `${this.$n(tmp[0], { useGrouping: false })}-${this.$n(tmp[1], { useGrouping: false })}` })
    //     })
    // },
    async searchData () {
      await this.loadData()
    },
    remove (item) {
      this.changeStatus(warehouseServiceBaseUrl, warehouseRentToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { fiscal_year_id: this.search.fiscal_year_id, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(warehouseServiceBaseUrl, warehouseRentList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
